<template>
  <!-- <small>loading...</small> -->
  <img class="gifSmall" src="/img/loading-sm.gif"/>
</template>

<script>
export default {
  name: "LoadingGifSmall",
};
</script>

<style>
.gifSmall {
  height: 25px;
  width: auto;
}
</style>
